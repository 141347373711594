import Link from "next/link";
import { PropsWithChildren } from "react"
import { twMerge } from "tailwind-merge";
import { PropsWithClass, PropsWithClassAndStyle } from "utils/props";

function FooterLink({ children, href, external }: PropsWithChildren<{ href: string; external?: boolean }>) {
  if (external) {
    return <a className="text-dark-grey w-fit" href={href}>{children}</a>
  }
  return <Link href={href} passHref>
    <a className="text-dark-grey w-fit">{children}</a>
  </Link>
}

function FooterSection({ name, children, className, style }: PropsWithClassAndStyle<PropsWithChildren<{ name: string }>>) {
  return <div className={className} style={style}>
    <h2 className="text-lg font-bold text-secondary">{name}</h2>
    <div className="flex flex-col gap-0.5 w-6 xl:w-7 2xl:w-10 mt-1">
      {children}
    </div>
  </div>
}

export default function Footer({ className }: PropsWithClass<{}>) {
  return <footer className={twMerge("w-full px-2 3xl:px-10 py-2 bg-[#f7f8fc]", className)}>
    <div className="flex flex-row justify-between">
      <div className="gap-2 flex-1 mr-1">
        <h2 className="text-xl font-bold text-secondary">Research Helper</h2>
        <div className="flex flex-col gap-1 mt-1 text-dark-grey">
          <p>Платформа для подачи, экспертизы и публикации<br />научно-методической медицинской информации</p>
          <span>
            <b>Контакты</b>
            <p className="mt-0.5">
              Администрация: <a className="text-secondary" href="mailto:admin@research-helper.ru">admin@research-helper.ru</a><br />
              Поддержка: <a className="text-secondary" href="mailto:support@research-helper.ru">support@research-helper.ru</a>
            </p>
          </span>
          <span className="flex flex-row gap-1">
            <span className="flex flex-col w-max">
              <a href="https://ropniz.ru/" className="text-secondary underline max-3xl:w-4"><b>РОПНИЗ</b></a>
              <span className="flex flex-row gap-0.5 mt-0.5">
                <a href="https://t.me/ropniz"><img src="/images/social-telegram.svg" width="36" height="36" className="max-w-none" /></a>
                <a href="https://vk.com/ropniz"><img src="/images/social-vk.svg" width="36" height="36" className="max-w-none" /></a>
              </span>
            </span>
            <span className="flex flex-col">
              <a href="https://gnicpm.ru/" className="text-secondary underline flex-1"><b>ФГБУ «НМИЦ ТПМ» Минздрава России</b></a>
              <span className="flex flex-row gap-0.5 mt-0.5">
                <a href="https://t.me/fgbunmictpm"><img src="/images/social-telegram.svg" width="36" height="36" /></a> {/*TODO: Make grey*/}
                <a href="https://vk.com/gnicpmru"><img src="/images/social-vk.svg" width="36" height="36" /></a> {/*TODO: Make grey*/}
              </span>
            </span>
          </span>

        </div>
      </div>
      <div className="flex flex-row gap-0.5 xl:gap-1 4xl:gap-2">
        <FooterSection name="Авторам">
          <FooterLink href="/info/services">Преимущества</FooterLink>
          <FooterLink href="/intellichecker">Проверить рукопись</FooterLink>
          <FooterLink href="#">Как писать статьи</FooterLink>
        </FooterSection>
        <FooterSection name="Редакторам">
          <FooterLink href="/info/editors">Стать редактором</FooterLink>
          <FooterLink href={encodeURI('/journals/@me')}>Мой журнал</FooterLink>
        </FooterSection>
        <FooterSection name="Рецензентам">
          <FooterLink href="/info/reviewers">Стать подтвержденным рецензентом</FooterLink>
          <FooterLink href={encodeURI('/profile/@me?tab=reviews')}>Мои рецензии</FooterLink>
        </FooterSection>
        <FooterSection name="Ресурсы">
          <FooterLink href="http://51.250.66.81:8085/index.php/Заглавная_страница" external>Справка</FooterLink>
          <FooterLink href="/static/guide.pdf" external>Руководство пользователя</FooterLink>
          <FooterLink href="https://prevmed.elpub.ru/" external>Терапия без границ</FooterLink>
          <FooterLink href="https://ropniz.ru/doctors/library" external>Библиотека профилактической кардиологии</FooterLink>
          <FooterLink href="https://ropniz.ru/doctors/events" external>Программы РОПНИЗ</FooterLink>
          <FooterLink href="/courses">Курсы, вебинары и обучающие мероприятия</FooterLink>
          <FooterLink href="/journals">Журналы платформы</FooterLink>
        </FooterSection>
      </div>
    </div>
    <span className="flex flex-row justify-between  mt-4">
      <h4 className="text-grey">©️ 2024 РОПНИЗ и ФГБУ «НМИЦ ТПМ» Минздрава России</h4>
      <p className="text-right text-sm text-secondary"><Link href="/legal/privacy" passHref><a>Политика конфиденциальности</a></Link>, <Link href="/legal/oss-licenses"><a>Лицензии открытого ПО</a></Link></p>
    </span>

    {/* <div className="flex flex-row justify-between opacity-50"> */}
    {/*   <img src="/images/nmic.png" className="w-max h-max" /> */}
    {/*   <img src="/images/ropniz.png" className="w-max h-max" /> */}
    {/* </div> */}
  </footer>
}

